.ice-trace {
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: dash 2s ease-in-out forwards;
}

@keyframes dash {
  0% {
    stroke-dashoffset: 1000;
  }

  50% {
  }

  100% {
    stroke-dashoffset: 0;
  }
}

:root {
  --zamboni-width: 192px;
  --zamboni-length: 150px;
}

.zamboni {
  position: fixed;
  width: var(--zamboni-width);
  height: var(--zamboni-length);
  animation: zamboni-circle 16s linear forwards;
  left: -50%;
  top: 0%;
}

@keyframes zamboni-circle {
  0% {
    transform: rotate(0deg);
    top: 24px;
    left: calc(0% - var(--zamboni-length) - 80px);
  }
  /* top-left->top-right */
  27% {
    transform: rotate(0deg);
    top: 24px;
    left: calc(100% - var(--zamboni-width) - 36px);
  }
  /* top-right rotating */
  43% {
    transform: rotate(90deg);
    top: 40px;
    left: calc(100% - var(--zamboni-width));
  }
  /* top-right -> bottom-right */
  73% {
    transform: rotate(90deg);
    top: calc(100% - var(--zamboni-width) - 36px);
    left: calc(100% - var(--zamboni-width));
  }
  /* bottom-right rotating */
  90% {
    transform: rotate(180deg);
    top: calc(100% - var(--zamboni-width));
    left: calc(100% - var(--zamboni-width) - 44px);
  }
  /* bottom-right->bottom-left */
  100% {
    transform: rotate(180deg);
    top: calc(100% - var(--zamboni-width));
    left: calc(0% + 36px);
  }
}

.summary-card {
  opacity: 0;
  animation: fade-in 5s ease-out forwards;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
