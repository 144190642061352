.container {
  position: fixed;
  width: 100dvw;
  height: 100dvh;
  display: flex;
  flex-direction: column;
  animation: slideIn 0.3s ease-in-out;
}

.container.slideOut {
  animation: slideOut 0.3s ease-in-out forwards;
}

@keyframes slideIn {
  0% {
    left: 100%;
  }

  100% {
    left: 0;
  }
}

@keyframes slideOut {
  0% {
    left: 0;
  }

  100% {
    left: -100%;
  }
}

/* apply this when the wrapped is showing */
html.noScroll {
  overflow: hidden;
}

.tapTarget {
  position: absolute;
  width: 50dvw;
  height: 100dvh;
}

.tapTarget.left {
  left: 0;
}

.tapTarget.right {
  right: 0;
}

.topBar {
  background-image: linear-gradient(rgba(0, 0, 0, 0.07), rgba(0, 0, 0, 0));
  padding: 12px 16px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 8px;
}

.progressBarContainer {
  width: 100%;
  height: 8px;
  display: grid;
  grid-auto-columns: minmax(0, 1fr);
  grid-auto-flow: column;
  grid-gap: 5px;
}

.progressBar {
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 8px;
  height: 100%;
}

.progressBar.filled {
  background-color: rgba(255, 255, 255, 1);
}

.content {
  flex: 1;
}

@keyframes marquee {
  0% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(-100%);
  }
}

.text-marquee {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  span {
    will-change: transform;
    transform: translateX(0%);
    white-space: nowrap;
    /* working around safari's BS: https://stackoverflow.com/questions/23123696/css-animation-works-in-chrome-but-not-safari */
    animation: marquee 8s steps(5000) infinite;
  }
}
